<template>
	<a-dropdown :trigger="[trigger ? trigger : 'click']" :class="shape === 'button' ? '' : 'more_actions_dropdown'">
		<a-button shape="circle" type="link" icon="more" v-if="shape !== 'button' && shape !== 'slot'"
			@click.stop="() => { }" />
		<slot v-else-if="shape === 'slot'" @click.stop="() => { }"></slot>
		<a-button style="margin-left: 8px" v-else> {{ buttonText || 'Unnamed' }} <a-icon type="down" /> </a-button>
		<a-menu slot="overlay" @click="(e) => emitEvent(e)">
			<a-menu-item :key="item.value" v-for="item in items">
				<div class="dF aC">
					<span style="width:15px; height: 100%; display:inline-block" v-if="item.icon && item.icon.trim()"
						class="mr-1 text-black hover:text-primary">
						<i :style="item.value.includes('delete') ? 'color:#FD647C' : ''" style="margin-right: 0;"
							:class="item.icon.includes('fa-') ? `fa ${item.icon}` : `fe ${item.icon}`"
							v-if="item.icon.includes('fa-') || item.icon.includes('fe-')" />
						<span v-else-if="item.icon.includes('<svg')"
							:style="item.value.includes('delete') ? 'color:#FD647C' : ''" class="dF aC jC"
							v-html="item.icon"></span>
						<a-icon :style="item.value.includes('delete') ? 'color:#FD647C' : ''" :type="item.icon" v-else />
					</span>
					<a-tooltip v-if="item.info">
						<template slot="title">
							{{ item.info }}
						</template>
						{{ item.label }}
					</a-tooltip>
					<div v-else>
						{{ item.label }}
					</div>
				</div>
			</a-menu-item>
		</a-menu>
	</a-dropdown>
</template>

<script>
export default {
	props: ['items', 'val', 'shape', 'buttonText', 'trigger'],
	methods: {
		emitEvent(e) {
			let item = this.items.find(x => x.value === e.key)
			let obj = { key: e.key, path: e.keyPath, item, val: this.val }
			if (item.action && typeof item.action === 'function') {
				return item.action()
			}
			this.$emit('action', obj)


		}
	}
}
</script>

<style></style>
